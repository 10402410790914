import { IconButton, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { DisplayImage } from 'components/shared/display-image';
import { ClassAttendance, useClassAttendance } from 'hooks/admin/useClassAttendance';
import { Session } from 'hooks/useClass';
import { MemberSearch } from '../member-search.tsx';
import { useMutate } from 'hooks/useMutate';
import { Attendance } from 'hooks/useAttendance';
import { MemberSummaryDto, useMemberSummary } from 'hooks/useMemberSummary';
import { Toast } from 'components/shared/toast/index';
import { useMemo, useState } from 'react';
import { useSchoolContext } from 'components/context/school-context/index';
import { keyBy } from 'lodash';
import CancelIcon from '@mui/icons-material/Cancel';
import { Carousel } from 'components/home/dashboard/carousel';
import { useCustomTheme } from 'hooks/useCustomTheme';
import PersonIcon from '@mui/icons-material/Person';

type Props = {
  session: Session;
  date: string;
};

const Member = ({
  member,
  cancelled,
  onClick,
}: {
  member: MemberSummaryDto;
  cancelled: boolean;
  onClick: (a: MemberSummaryDto) => void;
}) => {
  return (
    <Box my={1} display={'flex'}>
      <DisplayImage firstName={member.firstName} picture={member.picture} size={25} sx={{ mr: 2 }} />
      <Typography variant={cancelled ? 'h5' : 'h4'} my={'auto'} key={member.id}>
        {`${member.firstName}. ${member.lastName.substring(0, 1).toUpperCase()}`}
      </Typography>
      {cancelled ? (
        <Box sx={{ ml: 'auto', my: 'auto' }}>
          <Typography color="error">Cancelled</Typography>
        </Box>
      ) : (
        <IconButton size="small" onClick={() => onClick(member)} sx={{ ml: 'auto', my: 'auto', height: 20, width: 20 }}>
          <CancelIcon />
        </IconButton>
      )}
    </Box>
  );
};

const Body = ({ memberData, session, date }: Props & { memberData: MemberSummaryDto[] }) => {
  const { small } = useCustomTheme();
  const attendees = useClassAttendance(session.id, date);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const { create: book } = useMutate<Attendance>('attendance', {});

  const find = useMemo(() => keyBy(memberData, 'id'), [memberData]);

  const handleCheckin = (user: MemberSummaryDto) => {
    book(
      { userId: user.id, classId: session.id, date, update: false },
      {
        onError: () => setErrorMessage('Unable to check in - No valid memberships'),
        onSuccess: i => setMessage(`${find[i.data.userId]?.firstName} signed in`),
      }
    );
  };

  const handleCancel = (user: MemberSummaryDto) => {
    book(
      { userId: user.id, classId: session.id, date, update: true },
      {
        onError: () => setErrorMessage('Unable to cancel checkin'),
        onSuccess: i => setMessage(`Cancelled checkin for ${find[i.data.userId]?.firstName}`),
      }
    );
  };

  const groupedAttendees = useMemo(
    () =>
      attendees.data
        ?.sort((a, b) => a.id - b.id)
        .reduce(
          (acc, child, index) => {
            !!index && index % (small ? 5 : 10) == 0 ? acc.push([child]) : acc[acc.length - 1].push(child);
            return acc;
          },
          [[] as ClassAttendance[]]
        ) || [],
    [attendees, find, small]
  );

  return (
    <Box width={1}>
      <Toast message={errorMessage} severity="error" open={!!errorMessage} onClose={() => setErrorMessage('')} />
      <Toast message={message} open={!!message} onClose={() => setMessage('')} />
      <MemberSearch onClick={handleCheckin} members={memberData} />
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography variant="h3">{'Attendees'}</Typography>
        <Box display={'flex'}>
          <Typography m={'auto'} variant="h3">{`${
            attendees?.data?.reduce((a, i) => (!i.cancelled ? a + 1 : a), 0) || 0
          } `}</Typography>
          <PersonIcon />
        </Box>
      </Box>
      <Carousel height={small ? 160 : 300}>
        {groupedAttendees.map((sArray, idx) => (
          <Box width={1} ml={3} key={`g${idx}`} sx={{ scrollSnapAlign: 'center' }}>
            {sArray.map(i => (
              <Box key={i.id} maxWidth={1} width={350}>
                <Member member={find[i.id]} cancelled={i.cancelled} onClick={handleCancel} />
              </Box>
            ))}
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export const MemberAttendance = ({ session, date }: Props) => {
  const { school } = useSchoolContext();
  const { data: memberData } = useMemberSummary(school.id);

  if (!memberData) return null;

  return <Body memberData={memberData} session={session} date={date} />;
};
