import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import logo from '../../assets/mm-logo.svg';
import { useSchoolContext } from 'components/context/school-context';
import { contrast } from 'Theme';

type Props = {
  stage: number;
  buttonText: string;
  onClick?: () => void;
  back?: () => void;
  disabled?: boolean;
};

export const Footer = ({ onClick, back, buttonText, stage, disabled }: Props) => {
  const { school } = useSchoolContext();
  const dotSx = { mx: 1, borderRadius: '50%', height: 10, width: 10 };

  return (
    <Box position={'absolute'} bottom={0} width={1}>
      <Box height={50} display={'flex'} width={1} justifyContent={'space-between'}>
        <Box width={140} display={'flex'} justifyContent={'end'} pr={10}>
          {!!back && (
            <Button variant={'text'} sx={{ color: contrast(school.priColor) }} onClick={back}>
              {'< Back'}
            </Button>
          )}
        </Box>
        <Box display={'flex'} mt={5}>
          <Box sx={dotSx} bgcolor={stage === 1 ? 'white' : '#ffffff80'} />
          <Box sx={dotSx} bgcolor={stage === 2 ? 'white' : '#ffffff80'} />
          <Box sx={dotSx} bgcolor={stage === 3 ? 'white' : '#ffffff80'} />
          <Box sx={dotSx} bgcolor={stage === 4 ? 'white' : '#ffffff80'} />
          <Box sx={dotSx} bgcolor={stage === 5 ? 'white' : '#ffffff80'} />
        </Box>
        <Box width={140} display={'flex'} justifyContent={'end'} pr={10}>
          {!!onClick && (
            <Button disabled={disabled} variant={'text'} sx={{ color: contrast(school.priColor) }} onClick={onClick}>
              {buttonText}
            </Button>
          )}
        </Box>
      </Box>
      <Box width={1} mt={2} height={50} display={'flex'} justifyContent={'center'} bgcolor={'white'}>
        <Box width={1} height={50} display={'flex'} justifyContent={'center'} bgcolor={'white'}>
          <Box width={50} component={'img'} src={logo} borderRadius={'50%'} />
          <Typography fontSize={20} sx={{ mt: 2, ml: 2, color: '#0094A7' }}>
            powered by Martial Manager
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
