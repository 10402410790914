/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, MenuItem, Typography } from '@mui/material';
import { Session } from 'hooks/useClass';
import { duration, friendlyTime } from '../../../utils/time';
import { useUserContext } from 'components/context/user-context';
import { useSchoolContext } from 'components/context/school-context';
import PlaceIcon from '@mui/icons-material/Place';
import { grey } from '@mui/material/colors';

type Props = {
  classes: Session;
  handleClick: (c: Session) => void;
  closure?: string;
};

export const ClassCard = ({ classes: c, handleClick, closure }: Props) => {
  const { school } = useSchoolContext();
  const { users } = useUserContext();
  const registeredUsers = users.filter(i => c.attendance.includes(i.id));

  return (
    <Box sx={{ display: 'flex', borderBottom: `2px solid ${grey[200]}` }} key={c.id}>
      <MenuItem disabled={!!closure} sx={{ height: 70, width: 1, p: 0 }} onClick={() => handleClick(c)}>
        {closure && (
          <Box
            display={'flex'}
            height={1}
            width={1}
            position={'absolute'}
            sx={{ backgroundColor: '#e9e9e9d2', borderRadius: 6 }}
          >
            <Typography variant="h2" margin={'auto'}>
              {closure}
            </Typography>
          </Box>
        )}
        <Box width={'100%'} display={'flex'}>
          <Box sx={{ height: 70, pt: 3 }} width={1}>
            <Box display={'flex'} pl={2}>
              <Box width={100} textAlign={'start'}>
                <Typography variant="h3" sx={{}} mr={1}>
                  {friendlyTime(c?.dateTime)}
                </Typography>
                <Typography variant="h5">{duration(c.duration)}</Typography>
              </Box>
              <Box textAlign={'start'} width={'calc(100% - 100px)'}>
                <Typography variant="h3" overflow={'hidden'} textOverflow={'ellipsis'}>
                  {`${c.subject.map(i => i.name).join(', ')}`}
                </Typography>
                <Box display={'flex'}>
                  <PlaceIcon sx={{ ml: -1, fontSize: 18, color: c.location.color }} />
                  <Typography variant="h5">{c.location.description}</Typography>
                </Box>
              </Box>
            </Box>
            <Box display={'flex'}></Box>
          </Box>
          {registeredUsers.length > 0 && (
            <Box
              position={'absolute'}
              sx={{ right: 6, width: 75, height: 32, borderRadius: 4, mt: 8, bgcolor: school.priColor }}
            >
              <Typography variant="h1" color={'white'}>
                &#10003;
              </Typography>
            </Box>
          )}
        </Box>
      </MenuItem>
    </Box>
  );
};
