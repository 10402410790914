import { Membership } from 'hooks/useMemership';
import { User } from 'hooks/useUser';

export enum PageType {
  member_settings = 'Members',
  home = 'Home',
  reporting = 'Reporting',
  user_settings = 'My profile',
  business_settings = 'Business profile',
  pricing = 'Pricing',
}

export const Tabs: Record<PageType, string[]> = {
  [PageType.member_settings]: ['Members', 'Memberships', 'Groups'],
  [PageType.reporting]: [],
  [PageType.home]: ['Dashboard', 'Timetable', 'Closures'],
  [PageType.user_settings]: ['Memberships', 'Children', 'My details'],
  [PageType.business_settings]: ['School', 'Terms', 'Locations', 'Subjects'],
  [PageType.pricing]: ['Memberships', 'Coupons'],
};

export enum Duration {
  ONGOING = 'ongoing',
  FIXED = 'until fixed date',
  DYNAMIC = 'period after signup',
}

export const getDuration = (membership: Membership) => {
  if (membership.endDate) {
    return Duration.FIXED;
  } else if (membership.limit) {
    return Duration.DYNAMIC;
  }
  return Duration.ONGOING;
};

export enum Interval {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export enum DisplayInterval {
  null = '',
  day = 'daily',
  week = 'weekly',
  month = 'monthly',
  year = 'yearly',
}

export enum PluralDisplayInterval {
  null = '',
  day = 'days',
  week = 'weeks',
  month = 'months',
  year = 'years',
}

export const displayInterval = (interval: Interval, frequency: number) => {
  return frequency > 1 ? `every ${frequency} ${PluralDisplayInterval[interval]}` : DisplayInterval[interval];
};

export type Day = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday' | 'all';

export enum Status {
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  TRIALING = 'trialing',
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
  EXPIRED = 'expired',
  PENDING = 'pending',
  PAUSED = 'paused',
  CANCELLING = 'cancelling',
  INACTIVE = 'inactive',
  PROSPECT = 'prospect',
}

export enum StatusColor {
  active = 'success',
  trialing = 'info',
  paused = 'info',
  incomplete = 'warning',
  incomplete_expired = 'warning',
  pending = 'warning',
  cancelling = 'warning',
  past_due = 'error',
  canceled = 'error',
  unpaid = 'error',
  expired = 'error',
  inactive = 'warning',
  prospect = 'info',
}

export const statusText = {
  [Status.INCOMPLETE]: 'incomplete',
  [Status.INCOMPLETE_EXPIRED]: 'expired',
  [Status.TRIALING]: 'trialling',
  [Status.ACTIVE]: 'active',
  [Status.PAST_DUE]: 'overdue',
  [Status.CANCELED]: 'canceled',
  [Status.UNPAID]: 'unpaid',
  [Status.EXPIRED]: 'expired',
  [Status.PENDING]: 'pending',
  [Status.PAUSED]: 'paused',
  [Status.CANCELLING]: 'cancelling',
  [Status.INACTIVE]: 'inactive',
  [Status.PROSPECT]: 'prospect',
};

export const ActiveStatus = [
  Status.ACTIVE,
  Status.PAST_DUE,
  Status.PENDING,
  Status.TRIALING,
  Status.UNPAID,
  Status.CANCELLING,
  Status.PAUSED,
];

export const ResumableStatus = [Status.CANCELLING, Status.PAUSED];

export const CancelableStatus = [Status.ACTIVE, Status.PAST_DUE, Status.PENDING, Status.TRIALING, Status.UNPAID];

export const isActive = (status: string) => !!ActiveStatus.includes(status as Status);

export const AlertStatus = [
  Status.INCOMPLETE,
  Status.PAST_DUE,
  Status.PENDING,
  Status.TRIALING,
  Status.UNPAID,
  Status.CANCELLING,
  Status.PAUSED,
];

export const statusOrder = {
  // active
  past_due: 1,
  unpaid: 2,
  cancelling: 3,
  incomplete: 4,
  pending: 5,
  paused: 6,
  trialing: 7,
  active: 8,
  // inactive
  canceled: 9,
  expired: 10,
  incomplete_expired: 11,
  inactive: 12,
  prospect: 13,
};

export const CancelledStatus = [Status.CANCELED, Status.EXPIRED, Status.INCOMPLETE_EXPIRED];

export const OverdueStatus = [Status.PAST_DUE, Status.UNPAID];

export type Location = {
  id: number;
  address: string;
  description: string;
  color: string;
};

export type CreateClass = {
  id?: number | null;
  schoolId: number;
  subjectId: number[];
  locationId: number;
  dateTime: string;
  duration: number;
  frequency: number;
  week: number;
  endDate: string | null;
  description?: string;
};

export type CreateUserDto = {
  id?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  birthday?: string;
  phone?: string;
  picture?: string;
  emergencyContact?: { name: string; phone: string };
};

export type UploadPictureDto = { id: number; picture: File };

export type UpdateMemberDto = {
  schoolId: number;
  userId?: number;
  user?: CreateUserDto;
  status?: string;
  lastAttendance?: string;
  lastLogin?: string;
  notifyAdmin?: boolean;
  active?: boolean;
  isAdmin?: boolean;
};

export type UpdateMemberCohortDto = {
  cohortId: number;
  userId: number;
  remove: boolean;
};

export type Enrolled = {
  user: User;
  membership: Membership;
  active: boolean;
};

export type Enrol = {
  membership: number;
};

export type AuthProvider = 'local' | 'google' | 'facebook' | 'parent';

export type SubscriptionParams = {
  cancel_at?: number;
  cancel_at_period_end?: boolean;
  pause_collection?: {
    behavior?: 'keep_as_draft' | 'mark_uncollectible' | 'void';
    resumes_at?: number;
  };
};
