import { useSearchParams, useNavigate } from 'react-router-dom';
export const useQueryState = (): [
  Record<string, string>,
  (params: Record<string, string>) => void,
  (k: string) => void
] => {
  const navigate = useNavigate();
  const [search] = useSearchParams();

  const remove = (deleteKey: string) => {
    const newParams = Object.fromEntries(search);
    delete newParams[deleteKey];
    const searchString = Object.entries(newParams)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');
    navigate(`?${searchString}`, { replace: true });
  };

  const setQuery = (params: Record<string, string>) => {
    const newParams = Object.entries({
      ...Object.fromEntries(search),
      ...params,
    });
    const searchString = newParams.map(([key, val]) => `${key}=${val}`).join('&');
    navigate(`?${searchString}`, { replace: true });
  };

  const query = Object.fromEntries(search);

  return [query, setQuery, remove];
};
