import { Box, Button, InputAdornment, MenuItem, TextField } from '@mui/material';
import { useSchoolContext } from 'components/context/school-context';
import { ModalBase } from 'components/shared/modal-base';
import { Coupon, useCoupons } from 'hooks/useCoupons';
import { Enrolment } from 'hooks/useMember';
import { useMutate } from 'hooks/useMutate';
import { useState } from 'react';

type Props = {
  open: boolean;
  onClose: () => void;
  enrolment: Enrolment;
};

export const EnrolmentPriceModal = ({ open, onClose, enrolment }: Props) => {
  const { school } = useSchoolContext();
  const [coupon, setCoupon] = useState<Coupon>();
  const [price, setPrice] = useState<number>(enrolment.price / 100);
  const [attendance, setAttendance] = useState<number>(enrolment.membership.attendanceLimit - enrolment.attendance);
  const couponsReponse = useCoupons(school.id);
  const { update } = useMutate('enrolment/update-subscription-price');
  const { update: updateClassPass } = useMutate('enrolment/update-class-pass');

  const isClassPass = enrolment.membership.type === 'class_pass';

  const { membership } = enrolment;
  const priceIntervalText = `every ${membership.frequency > 1 ? membership.frequency : ''} ${membership.interval}${
    !!(membership.frequency > 1) ? 's' : ''
  }`;

  const handleClose = () => {
    setCoupon(undefined);
    onClose();
  };

  const handleUpdate = () => {
    const data = {
      subscriptionId: enrolment.subscriptionId,
      ...(price !== enrolment.price / 100 && { price: price * 100 }),
      ...(coupon && { coupon }),
    };
    update(data);
    handleClose();
  };

  const handleUpdateClassPass = () => {
    updateClassPass({
      userId: enrolment.userId,
      schoolId: enrolment.schoolId,
      membershipId: enrolment.membershipId,
      attendance: enrolment.membership.attendanceLimit - attendance,
    });
    enrolment.attendance = enrolment.membership.attendanceLimit - attendance;
    handleClose();
  };

  return (
    <ModalBase title={isClassPass ? `Update class pass` : `Update subscription price`} open={open} onClose={onClose}>
      <Box mt={3}>
        {isClassPass ? (
          <>
            <TextField label={'remaining passes'} value={attendance} onChange={i => setAttendance(+i.target.value)} />
            <Box mt={4} display="flex" justifyContent="flex-end">
              <Button variant="contained" onClick={handleUpdateClassPass}>
                Update
              </Button>
            </Box>
          </>
        ) : (
          <>
            <TextField
              label={'price'}
              value={price}
              onChange={i => setPrice(+i.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                endAdornment: <InputAdornment position="end">{priceIntervalText}</InputAdornment>,
              }}
            />
            <TextField
              select
              label={'apply coupon'}
              value={coupon}
              onChange={i => setCoupon(couponsReponse.data?.find(c => c.id === i.target.value))}
            >
              {couponsReponse.data?.map(i => (
                <MenuItem key={i.id} value={i.id}>
                  {i.name}
                </MenuItem>
              ))}
            </TextField>
            <Box mt={4} display="flex" justifyContent="flex-end">
              <Button variant="contained" onClick={handleUpdate}>
                Update
              </Button>
            </Box>
          </>
        )}
      </Box>
    </ModalBase>
  );
};
