import { IconButton as Button, SxProps } from '@mui/material';
import { grey } from '@mui/material/colors';

export const ContactButton = ({
  destination,
  action,
  sx,
  children,
}: {
  action: 'mailto' | 'tel' | 'sms';
  destination?: string | null;
  sx?: SxProps;
  children: JSX.Element;
}) => {
  return (
    <>
      {action && (
        <Button
          sx={{
            m: 1,
            bgcolor: 'white',
            ':hover': {
              bgcolor: grey[100],
            },
            ...sx,
          }}
          size="small"
          href={`${action}:${destination}`}
        >
          {children}
        </Button>
      )}
    </>
  );
};
