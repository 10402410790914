import { Autocomplete, Box, MenuItem, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useMemo, useState } from 'react';
import { DisplayImage } from 'components/shared/display-image';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { MemberSummaryDto } from 'hooks/useMemberSummary';

type Props = {
  members: MemberSummaryDto[];
  onClick: (user: MemberSummaryDto, update: boolean) => void;
};

export const MemberSearch = ({ members, onClick }: Props) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const filteredUsers = useMemo(
    () =>
      (searchTerm &&
        searchTerm.length >= 3 &&
        members?.filter(i =>
          [`${i.firstName} ${i.lastName}`, i.lastName, i.email].some(
            v =>
              searchTerm && !!v?.toLowerCase().match(new RegExp(`(^${searchTerm.replace(/[^a-zA-Z0-9 ]/g, '')})`, 'g'))
          )
        )) ||
      [],
    [searchTerm]
  );

  const handleSelectUser = (user: MemberSummaryDto) => {
    onClick(user, false);
    setSearchTerm('');
  };

  return (
    <>
      <Box display={'flex'} justifyContent={'center'}>
        <Box width={300}>
          <Autocomplete
            inputValue={searchTerm}
            placeholder="Member search"
            options={filteredUsers.map(i => ({ label: `${i.firstName} ${i.lastName}`, value: i }))}
            renderOption={(_, { label, value }: { label: string; value: MemberSummaryDto }) => (
              <MenuItem key={value.id} onClick={() => handleSelectUser(value)}>
                <DisplayImage firstName={value.firstName} picture={value.picture} size={30} sx={{ mr: 2 }} />
                <Typography>{label}</Typography>
              </MenuItem>
            )}
            popupIcon={!filteredUsers.length ? <SearchIcon /> : <ArrowDropDownIcon />}
            open={filteredUsers.length > 0}
            noOptionsText={null}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Member search"
                autoComplete="off"
                onChange={({ currentTarget }) => setSearchTerm(currentTarget.value.toLowerCase())}
                sx={{ mt: 2 }}
              />
            )}
          />
        </Box>
      </Box>
    </>
  );
};
