import { Box, Switch, Typography } from '@mui/material';
import { CardBase } from 'components/shared/card-base';
import { DateTime } from 'luxon';
import background from './../../../../assets/birthday-bg.svg';
import { useSchoolContext } from 'components/context/school-context';
import { Carousel } from '../carousel';
import { DisplayImage } from 'components/shared/display-image';
import { useCustomTheme } from 'hooks/useCustomTheme';
import CakeIcon from '@mui/icons-material/Cake';
import TextsmsIcon from '@mui/icons-material/Textsms';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import { contrast } from 'Theme';
import { useSchoolMembers } from 'hooks/admin/useSchoolMembers';
import { friendlyDate } from 'utils/time';
import { ContactButton } from './contact-button';
import { useState } from 'react';

export const Birthdays = () => {
  const { school } = useSchoolContext();
  const { cardWidth } = useCustomTheme();
  const { data: members } = useSchoolMembers(school.id);
  const [window, setWindow] = useState(7);

  if (!members?.length) return null;

  const now = DateTime.now();

  const range = new Array(window).fill('').map((_, i) => now.plus({ days: i }).toFormat('MM-dd'));

  const birthdays = members
    .filter(member => range.includes(member.user.birthday?.substring(5, 10) || ''))
    .map(m => ({ user: m.user, date: m.user.birthday?.substring(5, 10) || '' }));

  const getText = (date?: string) => {
    if (!date) return '';
    if (range[0].includes(date)) {
      return 'Today';
    } else if (range[1].includes(date)) {
      return 'Tomorrow';
    } else {
      return friendlyDate(`${DateTime.now().year}${date}`);
    }
  };

  return (
    <CardBase sx={{ height: 'fit-content', m: 3 }}>
      <Box bgcolor={`${school.priColor}`}>
        <Box
          sx={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'right',
          }}
        >
          <Box mb={1} color={contrast(school.priColor)} display={'flex'} p={3} justifyContent={'space-between'}>
            <Box display="flex">
              <CakeIcon sx={{ fontSize: 30 }} />
              <Typography variant="h3" m={'auto'} ml={2}>
                {`Birthdays`}
              </Typography>
            </Box>
            <Box display={'flex'}>
              <Typography m={'auto'}>{`next ${window} days`}</Typography>
              <Switch color="secondary" onChange={e => setWindow(e.currentTarget.checked ? 30 : 7)}></Switch>
            </Box>
          </Box>
          <Carousel height={70} color={school.priColor}>
            {birthdays
              .sort((a, b) => +a.date.replace('-', '') - +b.date.replace('-', ''))
              .map(({ user, date }, idx) => (
                <Box
                  key={`g${idx}`}
                  sx={{
                    scrollSnapAlign: 'center',
                  }}
                >
                  <Box sx={{ pl: 3, width: cardWidth }}>
                    <Typography variant={'h3'} color={contrast(school.priColor)}>
                      {getText(date)}
                    </Typography>
                    <Box display={'flex'} mt={2} justifyContent={'space-between'}>
                      <Box display={'flex'}>
                        <DisplayImage firstName={user.firstName} picture={user.picture} size={36} />
                        <Typography color={contrast(school.priColor)} m={3} variant="h2">{`${
                          user.firstName
                        }. ${user.lastName.substring(0, 1).toUpperCase()}`}</Typography>
                      </Box>
                      <Box mr={3}>
                        <ContactButton action="mailto" destination={user.email}>
                          <EmailRoundedIcon color={'primary'} />
                        </ContactButton>
                        <ContactButton action="tel" destination={user.phone}>
                          <LocalPhoneRoundedIcon color={'primary'} />
                        </ContactButton>
                        <ContactButton action="sms" destination={user.phone}>
                          <TextsmsIcon color={'primary'} />
                        </ContactButton>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
          </Carousel>
        </Box>
      </Box>
    </CardBase>
  );
};
