import axios from 'axios';
import { useMutation } from 'react-query';
import { Kiosk } from './useKiosk';

type Attendance = { userId: number; classId: number; date: string };

export const useKioskAttendance = (
  kiosk: Kiosk,
  onSuccess: (r: { classId: number; checkinStatus: string }) => void
) => {
  const { uuid, token } = kiosk;

  const { mutate: create } = useMutation(
    (data: Attendance) =>
      axios
        .post<any, { data: { classId: number } }>(`/kiosk/attendance`, data, { headers: { uuid, token } })
        .then(r => ({ ...r.data, checkinStatus: 'success' }))
        .catch(e => ({ ...e.response.data, checkinStatus: 'error' })),
    {
      onSuccess,
    }
  );

  return { create };
};
