import { useQueryState } from 'hooks/useQueryState';
import { Grid } from '@mui/material';
import { PageType, Tabs } from 'utils/types';
import { PersonalSettings } from './personal-settings';
import { ChildrenSettings } from './children-settings';
import { Memberships } from './memberships';

export const UserSettings = () => {
  const [query] = useQueryState();

  const currentTab = query[`${query.page} tab`];
  const tab = (n: number): boolean => currentTab === Tabs[PageType.user_settings][n];

  return (
    <Grid container justifyContent={'center'} height={'94.5vh'} overflow={'scroll'}>
      {tab(0) && <Memberships />}
      {tab(1) && <ChildrenSettings />}
      {tab(2) && <PersonalSettings />}
    </Grid>
  );
};
