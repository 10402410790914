import { Autocomplete, Box, MenuItem, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useMemo, useState } from 'react';
import { useKioskContext } from 'components/context/kiosk-context';
import { KioskUser, useKioskUsers } from 'hooks/useKioskUsers';
import { DisplayImage } from 'components/shared/display-image';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

type Props = {
  onClick: (user: KioskUser) => void;
  onChange: () => void;
};

export const Search = ({ onClick, onChange }: Props) => {
  const { kiosk } = useKioskContext();
  const { users } = useKioskUsers(kiosk);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const filteredUsers = useMemo(
    () =>
      (searchTerm.length >= 3 &&
        users?.filter(i =>
          [`${i.firstName} ${i.lastName}`, i.lastName, i.email].some(
            v =>
              searchTerm && !!v?.toLowerCase().match(new RegExp(`(^${searchTerm.replace(/[^a-zA-Z0-9 ]/g, '')})`, 'g'))
          )
        )) ||
      [],
    [searchTerm]
  );

  const handleSelectUser = (user: KioskUser) => {
    onClick(user);
    setSearchTerm('');
  };

  return (
    <>
      <Box display={'flex'} justifyContent={'center'}>
        <Box width={300}>
          <Autocomplete
            placeholder="Member search"
            options={filteredUsers.map(i => ({ label: `${i.firstName} ${i.lastName}`, value: i }))}
            renderOption={(_, { label, value }: { label: string; value: KioskUser }) => (
              <MenuItem onClick={() => handleSelectUser(value)}>
                <DisplayImage firstName={value.firstName} picture={value.picture} size={30} sx={{ mr: 2 }} />
                <Typography>{label}</Typography>
              </MenuItem>
            )}
            popupIcon={!filteredUsers.length ? <SearchIcon /> : <ArrowDropDownIcon />}
            onClose={() => setSearchTerm('')}
            open={filteredUsers.length > 0}
            noOptionsText={null}
            sx={{ maxWidth: 300 }}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Member search"
                autoComplete="off"
                value={searchTerm}
                onChange={({ currentTarget }) => {
                  setSearchTerm(currentTarget.value.toLowerCase());
                  onChange();
                }}
                sx={{ maxWidth: 300 }}
              />
            )}
          />
        </Box>
      </Box>
    </>
  );
};
