import { Box } from '@mui/material';

export const ScrollContainer = ({
  children,
  fullWidth = false,
}: {
  children: JSX.Element | JSX.Element[];
  fullWidth?: boolean;
}) => (
  <Box pt={6} height={1} overflow={'scroll'} sx={{ maxWidth: fullWidth ? null : 1200 }}>
    <Box display={'flex'} flexWrap={'wrap'} justifyContent={'center'} mb={30}>
      {children}
    </Box>
  </Box>
);
