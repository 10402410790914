import { Box, Button, Card, IconButton, TextField, Typography } from '@mui/material';
import { useSchoolContext } from 'components/context/school-context';
import EditIcon from '@mui/icons-material/Edit';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { AddButton } from 'components/shared/add-button';
import { Coupon, useCoupons } from 'hooks/useCoupons';
import { useState } from 'react';
import { CouponModal } from 'components/modals/coupon-modal';
import { useMutate } from 'hooks/useMutate';
import { THEME } from 'Theme';
import { ScrollContainer } from 'components/shared/scroll-container';

export const Coupons = () => {
  const { school } = useSchoolContext();
  const { cardWidth } = useCustomTheme();
  const [coupon, setCoupon] = useState<Coupon>();
  const couponsResponse = useCoupons(school.id);
  const [open, setOpen] = useState(false);
  const [couponId, setCouponId] = useState<string>('');
  const [promoCode, setPromoCode] = useState('');
  const { create, destroy } = useMutate('coupon/promo-code', { invalidate: ['coupon'] });

  const handleCreate = () => {
    create({ promoCode, couponId });
    setPromoCode('');
    setCouponId('');
  };

  if (!couponsResponse.data) {
    return <></>;
  }
  const coupons = couponsResponse.data;

  return (
    <>
      <CouponModal
        key={coupon?.id}
        coupon={coupon}
        open={open || !!coupon}
        onClose={() => {
          setCoupon(undefined);
          setOpen(false);
        }}
      />
      <ScrollContainer>
        <>
          {coupons.map(c => (
            <Card key={c.id} sx={{ height: 'fit-content', borderRadius: 6, p: 4, my: 4, m: 3, width: cardWidth }}>
              <Box width={1} display={'flex'} justifyContent={'space-between'}>
                <Box alignItems={'space-between'}>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant="h3">{c.name}</Typography>
                  </Box>
                </Box>
                <Box mr={-2} mt={-2}>
                  <IconButton size="small" onClick={() => setCoupon(c)}>
                    <EditIcon sx={{ height: 18, width: 18 }} />
                  </IconButton>
                </Box>
              </Box>
              <Box>
                {!!c.promoCodes.length && (
                  <Typography variant="h5" sx={{ mt: 2 }}>
                    Promo codes
                  </Typography>
                )}
                {c.promoCodes.map((p, i) => (
                  <Box key={i} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography
                      variant="h2"
                      sx={{
                        px: 1,
                        backgroundColor: school.priColor,
                        color: THEME.palette.getContrastText(school.priColor),
                      }}
                    >
                      {p.code}
                    </Typography>
                    <Button variant="text" onClick={() => destroy(p.id)}>
                      Delete
                    </Button>
                  </Box>
                ))}
                {couponId === c.id ? (
                  <Box display={'flex'} mt={3}>
                    <TextField
                      sx={{ my: 0, mr: 3 }}
                      label={'promo code'}
                      value={promoCode}
                      onChange={e => setPromoCode(e.target.value.toUpperCase())}
                    ></TextField>
                    <Box pt={1}>
                      <Button variant="text" onClick={() => handleCreate()}>
                        Save
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <Box mt={3} height={37} textAlign={'center'}>
                    <Button sx={{ mt: 1 }} variant="text" onClick={() => setCouponId(c.id)}>
                      Add promo code
                    </Button>
                  </Box>
                )}
              </Box>
            </Card>
          ))}
          <AddButton label="Add coupon" onClick={() => setOpen(true)} sx={{ m: 3, width: cardWidth }} />
        </>
      </ScrollContainer>
    </>
  );
};
