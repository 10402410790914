import { Box, IconButton, Typography } from '@mui/material';
import { useSchoolContext } from 'components/context/school-context';
import { CardBase } from 'components/shared/card-base';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { useLocation } from 'hooks/useLocation';
import EditIcon from '@mui/icons-material/Edit';
import { LocationModal } from 'components/modals/location-modal';
import { useState } from 'react';
import { Location } from 'utils/types';
import { AddButton } from 'components/shared/add-button';
import { ScrollContainer } from 'components/shared/scroll-container';

export const Locations = () => {
  const { cardWidth } = useCustomTheme();
  const { school } = useSchoolContext();
  const { locationsResponse } = useLocation(school.id);
  const [location, setLocation] = useState<Location>();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <LocationModal
        key={location?.id}
        location={location}
        open={!!location || open}
        close={() => {
          setLocation(undefined);
          setOpen(false);
        }}
      />
      <ScrollContainer>
        <>
          {locationsResponse.data
            ?.sort((a, b) => a.id - b.id)
            .map(i => (
              <CardBase key={i.id} sx={{ display: 'flex', height: 70, my: 4, m: 3, width: cardWidth }}>
                <Box width={18} bgcolor={i.color} />
                <Box width={1} display={'flex'} justifyContent={'space-between'}>
                  <Box ml={2} my={'auto'} alignItems={'space-between'}>
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <Typography variant="h3" mb={1}>
                        {i.description}
                      </Typography>
                    </Box>
                    <Typography variant="h5">{i.address}</Typography>
                  </Box>
                  <Box mr={1} mt={1}>
                    <IconButton size="small" onClick={() => setLocation(i)}>
                      <EditIcon sx={{ height: 18, width: 18 }} />
                    </IconButton>
                  </Box>
                </Box>
              </CardBase>
            ))}
          <AddButton label="Add location" onClick={() => setOpen(true)} sx={{ m: 3, width: cardWidth }} />
        </>
      </ScrollContainer>
    </>
  );
};
