import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { FileUploadButton } from 'components/shared/file-upload-button';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useSchoolContext } from 'components/context/school-context';
import { useQueryClient } from 'react-query';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const TermsAndConditions = () => {
  const {
    school: { termsAndConditions },
  } = useSchoolContext();
  const queryClient = useQueryClient();
  const { small } = useCustomTheme();
  const [totalPages, setTotalPages] = useState<number>(0);
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => setTotalPages(numPages);

  return (
    <Grid container my={8} justifyContent={'center'} alignContent={'center'}>
      <Box mb={24}>
        <Box display={'flex'}>
          <Box my={4} display={'flex'} mx={'auto'}>
            <FileUploadButton
              onSuccess={() => {
                queryClient.invalidateQueries(['school']);
                queryClient.invalidateQueries(['member']);
              }}
            />
            <Typography variant="h2" ml={2} my="auto">
              Upload file
            </Typography>
          </Box>
        </Box>
        <Box sx={{ zIndex: 999, display: 'flex', overflow: 'hidden' }} pb={12}>
          {termsAndConditions ? (
            <Document
              loading={
                <Box width={200} textAlign={'center'}>
                  <CircularProgress />
                </Box>
              }
              file={termsAndConditions}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(totalPages), (el, index) => (
                <Page width={small ? 390 : 900} key={index} pageNumber={index + 1} />
              ))}
            </Document>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </Grid>
  );
};
