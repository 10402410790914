import { Box, Card, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import { useSchoolContext } from 'components/context/school-context';
import { ImageUploadModal } from 'components/modals/image-upload-modal';
import { AddButton } from 'components/shared/add-button';
import { CardBase } from 'components/shared/card-base';
import { DisplayImage } from 'components/shared/display-image';
import { useMutate } from 'hooks/useMutate';
import { User } from 'hooks/useUser';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { MemberModal } from 'components/admin/member-settings/member-modal';
import { CancelledStatus, UpdateMemberCohortDto, UploadPictureDto } from 'utils/types';
import { CohortBadge } from 'components/shared/cohort-badge';
import { Member } from 'hooks/useMember';
import { useCohorts } from 'hooks/useCohorts';
import { AdminEnrolmentModal } from '../admin-enrolment-modal';
import { EnrolmentCard } from 'components/shared/enrolment-card';
import { AdminUpdateButton } from 'components/shared/enrolment-card/admin-update-button';
import TextsmsIcon from '@mui/icons-material/Textsms';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import { ContactButton } from 'components/home/dashboard/birthdays/contact-button';

type Props = {
  user: User;
  member?: object & Member;
};

export const MemberCard = ({ user, member }: Props) => {
  const { school } = useSchoolContext();
  const [enrolmentsModal, setEnrolmentsModal] = useState(false);
  const [userDetailsModal, setUserDetailsModal] = useState(false);
  const cohortsResponse = useCohorts(school.id);
  const { create: updatePicture } = useMutate<UploadPictureDto>('user/upload-picture ', {
    invalidate: ['member'],
    headers: { targetId: user.id },
  });
  const { create: updateCohort } = useMutate<UpdateMemberCohortDto>('admin/member-cohort', {
    invalidate: ['member'],
  });

  const handleSelect = (cohortId: number) => {
    const remove = member?.cohorts.map(i => i.id).includes(Number(cohortId));
    updateCohort({ userId: user.id, memberId: member?.userId, schoolId: school.id, cohortId, remove });
  };

  const handleUpload = (file: File) => {
    const formData = new FormData();
    formData.append('picture', file);
    updatePicture(formData);
  };

  return (
    <Box m={3}>
      <AdminEnrolmentModal open={enrolmentsModal} user={user} onClose={() => setEnrolmentsModal(false)} />
      <MemberModal user={user} open={userDetailsModal} onClose={() => setUserDetailsModal(false)} />
      <CardBase sx={{ height: 'fit-content' }}>
        <Box width={1}>
          <Box display={'flex'} p={4} justifyContent={'space-between'}>
            <Box display={'flex'}>
              <Box display={'flex'} width={100}>
                <DisplayImage picture={user.picture} firstName={user.firstName} size={50} />
                <ImageUploadModal logoColor={school.priColor} onSuccess={handleUpload} round={true} />
              </Box>
              <Box p={2}>
                <Typography
                  textOverflow={'ellipsis'}
                  overflow={'hidden'}
                  textAlign={'left'}
                  variant="h2"
                  maxWidth={200}
                >{`${user.firstName} ${user.lastName}`}</Typography>
              </Box>
            </Box>
            <Box m={-3}>
              <IconButton size="small" onClick={() => setUserDetailsModal(true)}>
                <EditIcon sx={{ height: 18, width: 18 }} />
              </IconButton>
            </Box>
          </Box>
          <Box display={'flex'} width={1} textAlign={'center'} justifyContent={'center'}>
            <ContactButton sx={{ m: 3, mt: 0 }} destination={user.email} action={'mailto'}>
              <EmailRoundedIcon color={'primary'} />
            </ContactButton>
            <ContactButton sx={{ m: 3, mt: 0 }} destination={user.phone} action={'tel'}>
              <LocalPhoneRoundedIcon color={'primary'} />
            </ContactButton>
            <ContactButton sx={{ m: 3, mt: 0 }} destination={user.phone} action={'sms'}>
              <TextsmsIcon color={'primary'} />
            </ContactButton>
          </Box>
          <Box px={4} pb={4}>
            {member && (
              <Card style={{ padding: 16, boxSizing: 'border-box' }}>
                <Box width={1} display={'flex'} flexWrap={'wrap'}>
                  {member?.cohorts.map(i => (
                    <Box key={i.id} display={'flex'} p={1}>
                      <CohortBadge colors={i.colors.split(',')} sx={{ height: 20, width: 20 }} />
                      <Typography m={'auto'} variant="h4" ml={0.5}>
                        {i.name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                {cohortsResponse.data && (
                  <TextField
                    select
                    label={'Current grades'}
                    SelectProps={{
                      multiple: true,
                      value: member?.cohorts?.map(i => i.id) || [],
                      MenuProps: {
                        sx: { maxHeight: 190 },
                      },
                    }}
                  >
                    {cohortsResponse.data.map(c => (
                      <MenuItem onClick={() => handleSelect(c.id)} key={c.id} value={c.id}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Card>
            )}
          </Box>
          <AddButton
            label="Add membership"
            onClick={() => setEnrolmentsModal(v => !v)}
            sx={{ mx: 4, mb: 4, maxWidth: 700 }}
          />
          <Box>
            {member?.enrolments
              ?.filter(i => !CancelledStatus.includes(i.status))
              .map((e, i) => (
                <Box key={i} px={4} pb={4}>
                  <EnrolmentCard user={user} enrolment={e} button={<AdminUpdateButton enrolment={e} user={user} />} />
                </Box>
              ))}
          </Box>
          <Box>
            {member?.groupEnrolments
              ?.filter(i => !CancelledStatus.includes(i.status) && !(i.userId === user.id))
              .map((e, i) => (
                <Box key={i} px={4} pb={4}>
                  <EnrolmentCard user={user} enrolment={e} button={<AdminUpdateButton enrolment={e} user={user} />} />
                </Box>
              ))}
          </Box>
        </Box>
      </CardBase>
    </Box>
  );
};
