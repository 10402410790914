import { Alert, Snackbar, Typography } from '@mui/material';

export const Toast = ({
  message,
  open,
  onClose,
  severity = 'success',
}: {
  message: string;
  open: boolean;
  severity?: 'success' | 'error' | 'warning' | 'info';
  onClose: () => void;
}) => (
  <Snackbar
    open={open}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    autoHideDuration={3000}
    onClose={onClose}
  >
    <Alert variant="outlined" severity={severity} sx={{ width: '100%', bgcolor: 'white' }}>
      <Typography variant="body1">{message}</Typography>
    </Alert>
  </Snackbar>
);
