import { Box, Button, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { useSchoolContext } from 'components/context/school-context';
import { useSchoolMember } from 'hooks/admin/useSchoolMember';
import { useMemo, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import CancelIcon from '@mui/icons-material/Cancel';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { MemberModal } from 'components/admin/member-settings/member-modal';
import { MemberFilter } from './member-filter';
import { ActiveStatus, CancelledStatus, statusOrder, SubscriptionParams } from 'utils/types';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import { useMutate } from 'hooks/useMutate';
import { useCohorts } from 'hooks/useCohorts';
import { useMembership } from 'hooks/useMemership';
import { UpdateSubscriptionModal } from '../update-subscription-modal';
import { EnrolCard } from './enrol-card';
import { EnrolmentButton } from 'components/shared/enrolment-button';
import { useUserEnrolments } from 'hooks/admin/useUserEnrolments';
import { keyBy } from 'lodash';
import { useSchoolMembers } from 'hooks/admin/useSchoolMembers';

export const Enrolments = () => {
  const { school } = useSchoolContext();
  const { cardWidth } = useCustomTheme();
  const { data: cohorts } = useCohorts(school.id);
  const { data: memberships } = useMembership(school.id);
  const [selectedMember, setSelectedMember] = useState<number>();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [memberModalOpen, setMemberModalOpen] = useState(false);
  const [anchor, setAnchor] = useState<HTMLButtonElement>();
  const { data: enrolments } = useUserEnrolments(school.id);
  const { data: member } = useSchoolMember(school.id, selectedMember);
  const { data: schoolMembers } = useSchoolMembers(school.id);
  const { data: membership } = useMembership(school.id);

  const [cohortFilters, setCohortFilters] = useState<number[]>([]);
  const [membershipFilters, setMembershipFilters] = useState<number[]>([]);
  const [subscriptionUpdateOpen, setSubscriptionUpdateOpen] = useState(false);
  const filtersApplied = cohortFilters.length || membershipFilters.length;

  const memberMap = useMemo(() => keyBy(schoolMembers, 'userId'), [schoolMembers]);
  const membershipMap = useMemo(() => keyBy(membership, 'id'), [membership]);

  const { update } = useMutate<{ subscriptionIds: string[]; data: SubscriptionParams }>(
    'enrolment/batch-update-subscription'
  );

  const filteredEnrolments = useMemo(() => {
    return enrolments
      ?.sort((a, b) => statusOrder[a.status] - statusOrder[b.status])
      .filter(s =>
        membershipFilters.length
          ? !CancelledStatus.includes(s.status) && membershipFilters.includes(s?.membershipId)
          : true
      );
  }, [enrolments, membershipFilters]);

  const handleClick = (userId?: number) => {
    setSelectedMember(userId);
  };

  const handleSubscriptionUpdate = (data?: SubscriptionParams) => {
    if (filteredEnrolments && data) {
      update({
        subscriptionIds: filteredEnrolments
          .filter(e => ActiveStatus.includes(e.status) && membershipFilters.includes(e.membershipId))
          .map(e => e.subscriptionId)

          .flat(),
        data: data,
      });
    }
    setSubscriptionUpdateOpen(false);
  };

  if (!enrolments || !schoolMembers) {
    return <></>;
  }

  return (
    <Box mb={24} maxWidth={1200} justifyContent={'center'}>
      <MemberModal open={memberModalOpen} onClose={() => setMemberModalOpen(false)} />
      <UpdateSubscriptionModal open={subscriptionUpdateOpen} onClose={handleSubscriptionUpdate} type="pause" />
      <Box mt={6} display={'flex'} width={1} justifyContent={'center'}>
        <Box>
          <Box>
            {!!membershipFilters?.length && !!filteredEnrolments?.length && (
              <Box width={cardWidth} flexWrap={'wrap'} justifyContent={'space-between'} textAlign={'center'}>
                <Button onClick={() => setSubscriptionUpdateOpen(true)}>
                  <PauseCircleOutlineIcon sx={{ mr: 2 }} />
                  {'Pause memberships for selected members'}
                </Button>
              </Box>
            )}
          </Box>
          <Box display={'flex'} sx={{ width: cardWidth }}>
            <IconButton sx={{ height: 38, width: 38, mt: 4 }} onClick={e => setAnchor(e.currentTarget)}>
              <TuneIcon sx={{ color: school.priColor }} />
            </IconButton>

            {cohorts && memberships && (
              <MemberFilter
                memberships={memberships}
                onCohortChange={setCohortFilters}
                membershipFilters={membershipFilters}
                onMembershipChange={setMembershipFilters}
                anchor={anchor}
                onClose={() => setAnchor(undefined)}
              />
            )}
            <TextField
              autoComplete="off"
              value={searchTerm}
              onChange={({ currentTarget }) => {
                setSearchTerm(currentTarget.value.toLowerCase());
              }}
              sx={{ width: cardWidth }}
              InputProps={{
                endAdornment: (
                  <>
                    {selectedMember && (
                      <InputAdornment position="start">
                        <IconButton sx={{ mr: -4 }} onClick={() => handleClick()}>
                          <CancelIcon />
                        </IconButton>
                      </InputAdornment>
                    )}
                    <InputAdornment position="end">
                      <IconButton sx={{ mr: -4 }}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  </>
                ),
              }}
            />
          </Box>
          {!!filtersApplied && (
            <Box display={'flex'} width={1} justifyContent={'center'}>
              <Button
                variant="text"
                onClick={() => {
                  setCohortFilters([]);
                  setMembershipFilters([]);
                }}
              >
                Reset filters
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      {member && (
        <Grid container my={6} mb={24} maxWidth={1200} justifyContent={'center'}>
          <EnrolCard user={member.user} member={member} />
          {member.user?.children.map(c => (
            <EnrolCard key={c.id} member={member.children.find(i => i.userId === c.id)} user={c} />
          ))}
        </Grid>
      )}
      <Grid container my={6} maxWidth={1200} justifyContent={'center'} alignContent={'start'}>
        {!member && (
          <>
            {filteredEnrolments?.map((e, idx) => (
              <Box key={idx} sx={{ m: 3 }}>
                <EnrolmentButton
                  member={memberMap[e.userId]}
                  membership={membershipMap[e.membershipId]}
                  enrolment={e}
                  onClick={() => handleClick(e.userId)}
                />
              </Box>
            ))}
          </>
        )}
      </Grid>
    </Box>
  );
};
