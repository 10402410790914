import { Grid, Box, Typography, Button } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import useDraggableScroll from 'use-draggable-scroll';
import { useSchoolContext } from 'components/context/school-context';
import { MembershipCard } from 'components/memberships/membership-card';
import { useMembership } from 'hooks/useMemership';
import { CustomModal } from 'components/shared/custom-modal';
import { User } from 'hooks/useUser';
import { useMutate } from 'hooks/useMutate';
import { UserDetailsModal } from '../../../modals/user-details-modal';
import { CreateUserDto } from 'utils/types';
import { StartTrialModal } from './start-trial-modal';
import { grey } from '@mui/material/colors';

type Props = {
  open: boolean;
  user: User;
  onClose: () => void;
};

export const AdminEnrolmentModal = ({ open, user, onClose }: Props) => {
  const { school } = useSchoolContext();
  const ref = useRef(null);
  const { onMouseDown } = useDraggableScroll(ref);
  const useMembershipResponse = useMembership(school.id);
  const availableMemberships = useMemo(() => useMembershipResponse.data, [useMembershipResponse.data]);
  const [enrolData, setEnarolData] = useState<{
    membershipId: number;
    trialEnd?: number;
    requirePayment?: boolean;
  }>();

  const { create, response } = useMutate<string>('admin/enrolment', {
    invalidate: ['enrolment'],
    onSuccess: () => setEnarolData(undefined),
    headers: { href: location.href },
  });

  const { update } = useMutate('user', {
    invalidate: ['user'],
  });

  useEffect(() => {
    if (!!response) {
      window.location.assign(response);
    }
  }, [response]);

  const handleSubmit = (data?: CreateUserDto) => {
    if (data) {
      update(data);
    }
    if (enrolData) {
      create({ ...enrolData, userId: user.id });
    }
  };

  return (
    <>
      {enrolData && <UserDetailsModal user={user} onSubmit={handleSubmit} onClose={() => setEnarolData(undefined)} />}
      <CustomModal open={open} onClose={onClose}>
        <Grid container direction={'column'} m={'auto'}>
          <Grid container justifyContent="center">
            <Box
              sx={{
                overflow: 'scroll',
                display: 'flex',
                scrollSnapType: 'x mandatory',
              }}
              ref={ref}
              onMouseDown={onMouseDown}
            >
              {availableMemberships?.length ? (
                availableMemberships.map(m => (
                  <MembershipCard membership={m} key={m.id}>
                    <Box sx={{ mt: 2, pt: 1, borderTop: `solid 1px ${grey[300]}` }}>
                      <Box pt={1}>
                        <Typography variant="h5">{`Sign up ${user.firstName}`}</Typography>
                      </Box>
                      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-evenly' }}>
                        <Button
                          variant="contained"
                          key={user.id}
                          onClick={() => setEnarolData({ membershipId: m.id, requirePayment: false })}
                        >
                          {`Sign up`}
                        </Button>
                        {m.type !== 'class_pass' && (
                          <StartTrialModal onSubmit={d => setEnarolData({ ...d, membershipId: m.id })} />
                        )}
                      </Box>
                    </Box>
                  </MembershipCard>
                ))
              ) : (
                <Typography variant="h2" mb={4}>
                  No active memberships
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </CustomModal>
    </>
  );
};
