/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosError } from 'axios';
import { useSchoolContext } from 'components/context/school-context';
import { keyBy } from 'lodash';
import { useQuery, useQueryClient } from 'react-query';
import { iso } from 'utils/time';

export type ClassAttendance = {
  id: number;
  cancelled: boolean;
};

export const useClassAttendance = (classId: number, date: string) => {
  const { eventSource } = useSchoolContext();
  const queryClient = useQueryClient();

  eventSource.onmessage = m => {
    const payload = JSON.parse(m.data);
    if ('attendance' in payload) {
      queryClient.setQueriesData<{ id: string; cancelled: boolean }[]>(payload.key, cache => {
        const data = keyBy([...(cache || []), payload.attendance], 'id');
        return Object.values(data);
      });
    }
  };

  return useQuery<ClassAttendance[], AxiosError>(
    ['class-attendance', classId, date],
    async () => {
      const response = await axios.get(`/attendance/class/${classId}?date=${iso(date).toUTC().toString()}`);
      return response.data;
    },
    {
      enabled: true,
    }
  );
};
