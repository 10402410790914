import { Box, IconButton, Typography } from '@mui/material';
import { useUserContext } from '../../context/user-context/index';
import { useMutate } from '../../../hooks/useMutate';
import { useState } from 'react';
import { DateTime } from 'luxon';
import { getAge } from 'utils/time';
import { DisplayImage } from 'components/shared/display-image';
import { ImageUploadModal } from 'components/modals/image-upload-modal';
import { CardBase } from 'components/shared/card-base';
import EditIcon from '@mui/icons-material/Edit';
import { User } from 'hooks/useUser';
import { ChildModal } from 'components/modals/child-modal';
import { AddButton } from 'components/shared/add-button';
import { UploadPictureDto } from 'utils/types';
import { useSchoolContext } from 'components/context/school-context';
import { ScrollContainer } from 'components/shared/scroll-container';

const UserCard = ({ user, onEdit }: { user: User; onEdit: () => void }) => {
  const { school } = useSchoolContext();
  const { create: updatePicture } = useMutate<UploadPictureDto>('user/upload-picture', {
    invalidate: ['user'],
    headers: { targetId: user.id },
  });

  const handleUpload = (file: File) => {
    const formData = new FormData();
    formData.append('picture', file);
    updatePicture(formData);
  };

  return (
    <CardBase sx={{ height: 300, m: 4, p: 4 }}>
      <Box width={1}>
        <Box display={'flex'} justifyContent={'end'} m={-3}>
          <IconButton size="small" onClick={() => onEdit()}>
            <EditIcon sx={{ height: 18, width: 18 }} />
          </IconButton>
        </Box>
        <Box display={'flex'} pl={1}>
          <DisplayImage picture={user.picture} firstName={user.firstName} size={50} />
          <ImageUploadModal logoColor={school.priColor} onSuccess={handleUpload} round={true} />
          <Box p={2}>
            <Typography
              textOverflow={'ellipsis'}
              overflow={'hidden'}
              textAlign={'left'}
              variant="h2"
              maxWidth={200}
            >{`${user.firstName} ${user.lastName}`}</Typography>
            {user.birthday ? (
              <Box display={'flex'}>
                <Typography variant="h5">{DateTime.fromISO(user.birthday).toFormat('dd/MM/yy')}</Typography>
                <Typography variant="h5" pl={2}>{`(${getAge(user.birthday)})`}</Typography>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </Box>
        <Typography p={2} variant="h5">
          {user.email}
        </Typography>
        <Box>
          <Typography p={2} variant="h5">
            Emergency contact
          </Typography>
          <Box display={'flex'}>
            <Typography pl={2} variant="h3">
              {user.emergencyContact?.name}
            </Typography>
            <Typography pl={2} variant="h3">
              {user.emergencyContact?.phone}
            </Typography>
          </Box>
        </Box>
      </Box>
    </CardBase>
  );
};

export const ChildrenSettings = () => {
  const { user } = useUserContext();
  const [open, setOpen] = useState(false);
  const [editUser, setEditUser] = useState<User | undefined>();

  const handleClose = () => {
    setOpen(false);
    setEditUser(undefined);
  };

  return (
    <ScrollContainer>
      <ChildModal key={editUser?.id} open={open} user={editUser} onClose={handleClose} />
      <>
        {user.children.map(u => (
          <UserCard key={u.id} user={u} onEdit={() => setEditUser(u)} />
        ))}
      </>
      <AddButton label="Add child" onClick={() => setOpen(v => !v)} sx={{ my: 4, width: 200 }} />
    </ScrollContainer>
  );
};
