import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { useSession } from './useSession';

export type MemberSummaryDto = {
  id: number;
  firstName: string;
  lastName: string;
  picture: string;
  status: string;
  email: string;
};

export const useMemberSummary = (school: number): UseQueryResult<MemberSummaryDto[]> => {
  const { session } = useSession();

  return useQuery(
    ['member-summary', school],
    async () => {
      const r = await axios.get(`/member/summary`, { headers: { school } });
      return r.data;
    },
    {
      enabled: session && !!school,
    }
  );
};
